import { useEffect } from "react";

import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
  useLoaderData,
  useRouteError,
  Link,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { cacheHeader } from "pretty-cache-header";

import styles from "~/styles/app.css?url";
import { DebugBreakpoints } from "~/ui/Tailwind";
import { ClientHintCheck, getHints } from "~/utils/hints";

export const links = () => {
  return [
    { rel: "stylesheet", href: styles },
    {
      rel: "manifest",
      href: "/site.webmanifest",
    },
    {
      rel: "apple-touch-icon",
      sizes: "152x152",
      href: "/icons/app-icon-152x152.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "167x167",
      href: "/icons/app-icon-167x167.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/icons/app-icon-180x180.png",
    },
    { rel: "apple-touch-icon", href: "/icons/app-icon-192x192.png" },
  ];
};

export const meta: MetaFunction = () => [
  {
    title: `Middle of the Pack`,
  },
  { name: "mobile-web-app-capable", content: "yes" },
  { name: "apple-touch-fullscreen", content: "yes" },
  { name: "apple-mobile-web-app-capable", content: "yes" },
  { name: "apple-mobile-web-app-status-bar-style", content: "black" },
  { name: "apple-mobile-web-app-title", content: "motp" },
  { name: "msapplication-TileColor", content: "#18181b" },
  { name: "theme-color", content: "#18181b" },
  { name: "msapplication-config", content: "/browserconfig.xml" },
];

export async function loader({ context, request }: LoaderFunctionArgs) {
  const VARS = {
    ENV: context.cloudflare.env.CF_PAGES === "1" ? "production" : "development",
    CF_PAGES_COMMIT_SHA: context.cloudflare.env.CF_PAGES_COMMIT_SHA,
    CF_PAGES_BRANCH: context.cloudflare.env.CF_PAGES_BRANCH,
    SENTRY_DSN: context.cloudflare.env.VITE_SENTRY_DSN,
    SENTRY_ENV: context.cloudflare.env.VITE_SENTRY_ENV,
    SENTRY_RELEASE: context.cloudflare.env.VITE_SENTRY_RELEASE || context.cloudflare.env.CF_PAGES_COMMIT_SHA,
    SENTRY_ENABLED: context.cloudflare.env.SENTRY_ENABLED,
  };

  return json(
    {
      VARS,
      requestInfo: {
        hints: getHints(request),
      },
    },
    { headers: { "Cache-Control": cacheHeader({ maxAge: "5 min" }) } },
  );
}

export function shouldRevalidate() {
  return false;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>("root");
  return (
    <html lang="en" className="bg-background min-h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <ClientHintCheck />
        <Meta />
        <Links />
        <Favicon emoji="🏎" env={data?.VARS?.ENV} />
      </head>
      <body className="flex flex-col antialiased">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(function App() {
  const { VARS } = useLoaderData<typeof loader>();

  useEffect(() => {
    if (VARS.ENV === "production") {
      console.log(`Running ${VARS.ENV}[${VARS.CF_PAGES_COMMIT_SHA}][${VARS.CF_PAGES_BRANCH}]`);
    }
  }, [VARS.ENV, VARS.CF_PAGES_COMMIT_SHA, VARS.CF_PAGES_BRANCH]);

  return (
    <>
      <Outlet />
      {VARS.ENV === "development" ? <DebugBreakpoints /> : null}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.VARS = ${JSON.stringify(VARS)}`,
        }}
      />
    </>
  );
});

export function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="p-page max-w-screen-lg mx-auto w-full text-lg px-page flex flex-col items-center">
          {error.status ? `${error.status} – ${error.data?.message || error.statusText}` : error.toString()}
          <Link to="/" className="hover:underline">
            Go back
          </Link>
          {error.stack ? (
            <details className="w-full overflow-auto font-mono text-xs mt-4">
              <summary className="hover:underline mb-2">click here for more details</summary>
              <pre className="text-xs">
                <code>{error.stack}</code>
              </pre>
            </details>
          ) : null}
        </div>
        {/* add the UI you want your users to see */}
        <Scripts />
      </body>
    </html>
  );
}

function faviconTemplate(icon: string, env: string): string {
  return `<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22 style=%22transform: scaleX(-1); ${
    env === "development" ? "filter: hue-rotate(120deg);" : ""
  }%22><text y=%22.9em%22 font-size=%2290%22>${icon}</text></svg>`.trim();
}

const Favicon = ({ emoji, env }: { emoji: string; env: string }) => {
  const svg = faviconTemplate(emoji, env);
  const href = `data:image/svg+xml,${svg}`;
  return <link rel="icon" href={href} />;
};
